import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import "./all.sass"

import "./layout.scss"

import {
  Container,
  Hero,
  HeroHeader,
  HeroBody,
  HeroFooter,
  Tabs,
  TabList,
  Tab,
  TabLink,
} from "bloomer"
import AppHeader from "./Header"

const TemplateWrapper = ({ children, align }) => (
  <div>
    <Helmet title="Home | Gatsby + Bulma & Bloomer">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
      />
    </Helmet>
    <Hero className="hero-section" isFullHeight isColor="info">
      <HeroHeader>
        <AppHeader />
      </HeroHeader>

      <HeroBody>{children}</HeroBody>

      <HeroFooter>
        <Container>
          <Tabs isAlign="centered">
            <TabList>
              <Tab>
                <TabLink>
                  <a href="https://github.com/paweljm" target="blank">
                    <i className="fab fa-github" />
                  </a>
                  &nbsp;
                  <a
                    href="https://www.linkedin.com/in/pawel-marcinkiewicz-3a8ab8153/"
                    target="blank"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </TabLink>
              </Tab>
            </TabList>
          </Tabs>
        </Container>
      </HeroFooter>
    </Hero>
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper
